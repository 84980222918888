
/*****************************/
/*      STANDARD COLOR       */
/*****************************/

.di-svg,
.di-glyphicons,
.di-icon, [class*='di-icon-'],
.glyphicons {
    color: #0055cc;
}

/* Close icon always takes this specific color and is not affected by branding */
.di-icon-close {
    color: #666666;
    opacity: 1;
}

.di-icon-close:hover,
.di-icon-close:active {
    color: #333333;
    opacity: 1;
}
/* End of close icon styles */

/* ==== TEXT COLOR ==== */

.di-drakgray {
    color:#333333;
}
.di-gray {
    color:#666666;
}

/* ==== Background COLOR ==== */

.di-softgray-bg {
    background-color:#EDEDED;
}

.di-gray-bg {
    background-color:#666666;
}

/************* CALL OUT ***************/

.di-callout {
    background-color: #EDEDED;
    padding: 20px 20px 30px 20px;
    width: 100%;
    margin-bottom: 30px;
    /*min-height: 250px;*/
}
/* Behaveiour Override for Overlay/Model*/
.modal-body .di-callout{
    margin-bottom: 10px;
}
/** XSmall */
@media (max-width: 767px) {
    .di-callout {
        padding: 10px 10px 20px 10px;
    }
    .di-callout.xs-void{
        padding: 0px;
        background-color: transparent;
    }
}
/** Small Layout Overrides */
@media (min-width: 768px) and (max-width: 991px) {
    .di-callout {
        padding: 15px 15px 20px 15px;
    }
}

/*****************************/
/* Locally define components */
/*****************************/
/** CHECK BOX & RADIO */
.di-checkbox .input[type=checkbox]+.lbl.padding-16::before,
.di-radio input[type=radio]+.lbl.padding-16::before {
    margin-right: 16px;
}

.di-checkbox input[type=checkbox]+.lbl.padding-12::before,
.di-radio input[type=radio]+.lbl.padding-12::before {
    margin-right: 12px;
}

.di-checkbox input[type=checkbox]+.lbl.padding-8::before,
.di-radio input[type=radio]+.lbl.padding-8::before {
    margin-right: 8px;
}

.di-checkbox input[type=checkbox]+.lbl.padding-4::before,
.di-radio input[type=radio]+.lbl.padding-4::before {
    margin-right: 4px;
}

.di-checkbox input[type=checkbox]+.lbl.padding-0::before,
.di-radio input[type=radio]+.lbl.padding-0::before {
    margin-right: 0px;
}

.di-checkbox, .di-radio {
    display: inline-flex;
    align-items: center;
    min-height: 42px;
    margin-bottom: 0;
}
/* Hiding default checkbox and Radio button design */
.di-checkbox input[type=checkbox],
.di-radio input[type=radio] {
    opacity: 0;
    margin: 0;
    position: absolute;
    z-index: 12;
    width: 42px;
    height: 42px;
}

/* Disable outline when clicked*/
.di-checkbox input[type=checkbox]:checked,
.di-radio input[type=radio]:checked,
.di-checkbox input[type=checkbox]:focus,
.di-radio input[type=radio]:focus {
    outline: none !important;
}

.di-checkbox input[type=checkbox]+.lbl,
.di-radio input[type=radio]+.lbl {
    position: relative;
    z-index: 11;
    display: flex;
    align-items: center;
    margin: 0;
    line-height: 20px;
    min-height: 20px;
    min-width: 20px;
    font-weight: normal;
    margin-right: 5px;
}

/* Basic Checkbox styles */

.di-checkbox input[type=checkbox]+.lbl::before,
.di-radio input[type=radio]+.lbl::before {
    font-weight: normal;
    font-size: 11px;
    color: #666666;
    content: "\a0";
    background-color: transparent;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 19px;
    margin-right: 20px;
    margin-left: 6px;
    flex: none;
}

.di-checkbox input[type=checkbox]+.lbl.sm::before,
.di-radio input[type=radio]+.lbl.sm::before {
    line-height: 10px;
    margin-left: 11px;
}
.di-checkbox input[type=checkbox]+.lbl::before {
    border: 2px solid #666666;
    height: 30px;
    width: 30px;
    border-radius: 0;
}

.di-checkbox input[type=checkbox]+.lbl.sm::before {
    height: 20px;
    width: 20px;
}
/* Checked state styles */
.di-checkbox input[type=checkbox]:checked+.lbl::before{

    font-family: 'leapfrog';
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    content: '\e61d';
    font-size: 16px;
    padding-top:4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),inset 15px 10px -12px rgba(255, 255, 255, 0.1);
}

.di-checkbox input[type=checkbox]:checked+.lbl.sm::before{
    font-size: 11px;
    padding-top: 3px;
}
/* Hover state styles */
/*input[type=checkbox]:hover+.lbl::before,input[type=radio]:hover+.lbl::before,input[type=checkbox]+.lbl:hover::before,input[type=radio]+.lbl:hover::before {*/
    /*border-color: #1c7aad;*/
/*}*/

/* Shadow when checked or Active */
.di-checkbox input[type=checkbox]:active+.lbl::before,
.di-radio input[type=radio]:active+.lbl::before,
.di-checkbox input[type=checkbox]:checked:active+.lbl::before,
.di-radio input[type=radio]:checked:active+.lbl::before {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

/* Disabled state styles */
.di-checkbox input[type=checkbox]:disabled+.lbl::before,
.di-radio input[type=radio]:disabled+.lbl::before,
.di-checkbox input[type=checkbox][disabled]+.lbl::before,
.di-radio input[type=radio][disabled]+.lbl::before,
.di-checkbox input[type=checkbox].disabled+.lbl::before,
.di-radio input[type=radio].disabled+.lbl::before {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.30;
    filter: alpha(opacity=30);
}

/* Styles for Radio button */
.di-radio input[type=radio]+.lbl::before {
    height: 26px;
    width: 26px;
    border-radius: 18px;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 66px;
    padding-top: 5px;
    margin-left: 6px;
    box-shadow: 0 0 0 2px #666;
}

.di-radio input[type=radio]+.lbl.sm::before {
    border-radius: 13px;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 46px;
    padding-top: 4px;
    margin-left: 14px;
    height: 16px;
    width: 16px;
}
.di-radio input[type=radio]:checked+.lbl::before {
    border: 4px solid #fff;
    background-color: #666;
}
.di-radio input[type=radio]:checked+.lbl.sm::before {
    border-width: 2px;
}
.di-checkbox input[type=checkbox]+.lbl.sm::before,
.di-radio input[type=radio]+.lbl.sm::before {
	margin-right: 15px;
}
.di-checkbox label.di-checkbox ,
.di-radio label.di-radio {
	margin: 5px 0px;
}
.di-checkbox label.di-checkbox + .di-checkbox label.di-checkbox,
.di-radio label.di-radio + .di-radio label.di-radio {
	margin-left: 30px;
}

.di-checkbox input[type="checkbox"]:focus + .lbl::before,
.di-radio input[type="radio"]:focus + .lbl::before{
    outline: thin dotted;
}

@media (max-width: 767px) {
    .di-checkbox input[type=checkbox]+.lbl.sm::before,
    .di-radio input[type=radio]+.lbl.sm::before {
        margin-right: 10px;
    }
}

a.icon {
	text-decoration: none;
}
a.standalone {
    padding: 20px 0px;
    display: inline-block;
}
.di-container:focus{
	outline:none!important;
}
.container:focus{
  outline:none !important;
}
.di-container,
.di-container-d,
.tab-container {
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.45);
    margin-bottom: 15px;
}
.composite-layout__widget-container [class*=di-container] {
    margin-bottom: 0;
}
.di-container,
.di-container-d{
    background-color: #fff;
    padding: 30px 30px 40px 30px;
}

.di-container.first,
.di-container-d.first,
.tab-container.first {
    margin-top: 20px;
}

.di-container-d {
    padding: 20px 20px 30px 20px;
}
.di-container-d .bullet {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #CCCCCC;
    font-size: 14px;
    color: #666666;
}
.di-container-d .bullet:last-child {
    border-bottom: 0px solid #CCCCCC;
    padding-bottom: 0px;
}
.di-container-d .title {
    color: #333333;
    border-bottom: 3px solid #CCCCCC;
    font-size: 18px;
    font-weight: bolder;
    padding: 0px 0px 5px 0px;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.menu-separetor {
	padding-left:12px;
	padding-right:12px;
}
.menu-badge-holder {
	
	display:inline-block;
	position: relative;
	top: -5px;
}
.menu-badge {
	background-color:#D9E6F9;
	font-size: xx-small;
	color:black;
}
.menu-badge-lg {
	border-radius: 0px;
  	background-color: red;
}

/* Table Heading Related Stuff */
.di-rs-table .heading {
    border-top: 1px solid #cccccc;
    padding: 5px 0px;
}

.di-rs-table .heading .cell{
    border-right: 1px solid #cccccc;
    color: #666666;
    font-size: 14px;
    padding: 8px 15px;
    display: inline-block;
    width: 100%;
    line-height: 15px;
}

.di-rs-table .heading .cell.last.text-right {
    padding: 8px 5px;
}
.di-rs-table .heading .cell.first{
    padding:8px 5px;
}
.di-rs-table .heading .cell.last{
    border-right: 0px solid #cccccc;
}

/* DI - Table Data Row Related Stuff */
.di-rs-table .data {
    padding: 10px 0px;
    border-top: 1px solid #cccccc;
}
.di-rs-table .data .cell{
    color: #666666;
    padding: 0px 15px;
    display: inline-block;
    width: 100%;
    min-height: 31px;
}

.di-rs-table .data .cell.last.text-right {
    padding: 0px 5px;
}
.di-rs-table .data .cell.first {
    padding: 0px 5px;
}

.di-rs-table .data > div, .di-rs-table .heading > div {
    padding: 0px;
}
.di-rs-table > div {
    margin-left:0px;
    margin-right:0px;
}

/* Toggel switch */
.di-toggle-switch-container {
	width: 95px;
	height: 42px;
	/*margin:5px;*/
	overflow: hidden;
	border:1px solid #CCCCCC;
    display:inline-block;
    text-align: left;
}
.di-toggle-switch-container:focus {
	outline: thin dotted;
    outline: -webkit-focus-ring-color auto 5px;
}
.di-toggle-switch {
	width: 140px;
	height: 43px;
	font-family: Arial;
  	font-weight: bold;
  	font-size: 16px;
	color: #666;
	background-color:#EDEDED;
	margin-left: -40px;
}

.di-toggle-switch.active {
	background-color:#666666;
	color: #fff;
	margin-left: 0px;
}

.di-toggle-switch .label-on,
.di-toggle-switch .label-off {
	margin-top:-20px;
	width: 40px;
	display: inline-block;
	text-align: center;
  	vertical-align: middle;
}

.label-on:hover,
.label-slider:hover,
.label-off:hover {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.di-toggle-switch  .label-slider{
	margin-top:5px;
	background-color:#fff;
	width: 45px;
	height: 30px;
	display: inline-block;
}

.di-toggle-switch .label-slider{
	box-shadow: 1px 1px 0px 1px #CCCCCC;
}

.di-toggle-switch.active .label-slider {
	box-shadow: 0px 0px 0px 0px #CCCCCC;
}

/*Toggle switch small */
.di-toggle-switch-container.sm  {
	width: 85px;
	height: 35px;
}
.sm .di-toggle-switch .label-silder,
.sm .di-toggle-switch .label-slider {
	width: 35px;
	height: 22px;
}
.sm .di-toggle-switch .label-on,
.sm .di-toggle-switch .label-off {
	margin-top:-12px;
}

/* Toggle switch Animation*/
.di-toggle-switch.active,
.di-toggle-switch  {
  	transition:margin-left 0.25s linear;
}

/* Typography Style */
.text-light {
    color: #666666;
}

.text-long {
    font-size: 14px;
    color: #666666;
}
.text-legal {
    font-size: 13px;
    color: #666666;
}

.text-small {
    font-size: 14px;
}

/* Helper classes */
.bg-gray{
	background-color:#F2F2F2;
	border: 1px solid rgb(200, 200, 200);
	margin-bottom: 8px;
}

.width-100{
	width:100%;
}
.width-100 + .width-100 {
	margin-top:10px !important;
	margin-left: 0px !important;
}

/** Any Thing expect X-Small*/
@media (min-width: 768px) {
	.di-container {
	  border: 1px solid lightgray;
	}
}
/** Any thing Smaller then Medium */
@media (max-width: 991px) {


}
/** Larger then Small*/
@media (min-width: 992px) {


}
/** X-Small Layout Overrides */
@media (max-width: 767px) {

    .di-container{
        padding: 20px 20px 40px 20px;
  		border-bottom: 1px solid lightgray;
	}

    /** Help classes */
    .text-center-xs {
        text-align: center;
    }
    .xs-width-100{
        width:100%;
    }
	.xs-width-100 + .xs-width-100 {
		margin-top:10px;
		margin-left: 0px !important;
	}
    /** di-table Override */
    .di-rs-table .heading .cell{
        padding: 8px 7.5px;
    }
    .di-rs-table .data .cell{
        padding: 0px 7.5px;
    }

	/** Label Radio Check boxes */
	input[type=checkbox]+.lbl::before,
    input[type=radio]+.lbl::before {
		margin-right: 15px;
	}
}
/** Small Layout Overrides */
@media (min-width: 768px) and (max-width: 991px) { 
    .di-container {
        padding: 20px 20px 40px 20px;
	}

    /** Help classes */
    .text-center-sm {
        text-align: center;
    }

    /** di-table Override */
    .di-rs-table .heading .cell{
        padding: 8px 10px;
    }
    .di-rs-table .data .cell {
        padding: 0px 10px;
    }

}

/** Medium Layout Overrides */
@media (min-width: 992px) and (max-width: 1199px) {

    /** Help classes */
	.text-center-md {
        text-align: center;
    }
}

/** Large Layout Overrides */
@media (min-width: 1200px) {

	.text-center-lg {
        text-align: center;
    }
}


/* ==================================================== */
/* Images/Icon By classname and veriation               */
/* ==================================================== */

/* Loading Image format 1 */
.di-loader-dash-lg,
.di-loader-dash-md,
.di-loader-dash-sm {
    background-image: url('../img/loader-page.gif');
    background-repeat: no-repeat;
    background-size: 66px;
    height: 66px;
    width: 66px;
    display: inline-block;
}
.di-loader-dash-md {
    background-size: 50px;
    height: 50px;
    width: 50px;
}
.di-loader-dash-sm {
    background-size: 33px;
    height: 33px;
    width: 33px;
}

/* Loading Image format 2 */
.di-loader-solid-md,
.di-loader-solid-sm {
    background-image: url('../img/loader-m.gif');
    background-repeat: no-repeat;
    background-size: 26px;
    height: 26px;
    width: 26px;
    display: inline-block;
}
.di-loader-solid-sm {
    background-image: url('../img/loader-s.gif');
    background-size:17px;
    height: 17px;
    width: 17px;
}

/* DI Logo Footer transparentImage */
.di-logo-footer {
    height: 30px;
    width: 83px;
    display: inline-block;
}
/* DI Logo  white Image*/
.di-logo-white {
    /* background-image: url('../img/logo-di.png'); */
    background-repeat: no-repeat;
    background-size: 129px 47px;
    height: 47px;
    width: 129px;
    display: inline-block;
}

/* ========================================== */
/* =============  Animation  ===============  */
/* ========================================== */

.rotate{
    animation-name: ckw;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    animation-timing-function: linear
}
@keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/** Branding Default **/

.brand-type-a {
    background-color: #666666 !important;
    color: #FFFFFF !important;
}

.brand-type-a-10p {
    color: rgba(102, 102, 102, 0.1) !important;
}

.brand-type-a-bg-10p {
    background-color: rgba(102, 102, 102, 0.1) !important;
}

.brand-type-a-bg {
    background-color: #666666 !important;
}

.brand-type-a-text {
    color: #FFFFFF !important;
}

input::-ms-clear {
    display: none;
}

li.dropdown-item.active > button.btn.btn-tertiary{
    background-color: #ccc;
}